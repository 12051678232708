<template>
  <div class="form-recover-password">
    <BrandCustomCard subtitle="Recuperar contraseña">
      <v-form novalidate @submit.prevent="onSubmit">
        <v-text-field
          v-model.trim="$v.formFields.email.$model"
          label="Email"
          type="email"
          prepend-icon="mdi-at"
          :error-messages="checkFieldErrors('email')"
          @input="touchField('email')"
          @blur="touchField('email')"
        />
        <v-card-actions class="d-block">
          <!-- Form action buttons -->
          <FormButtons
            :accept-button-v-options="acceptButtonVOptions"
            align-buttons="center"
            accept-button-text="Recuperar"
            :accept-button-loading="formProcessing"
            cancel-button-hide
          />
          <!-- Link to others views -->
          <div class="mt-6 bottom-links">
            <router-link to="/login" class="grey--text">
              Acceder con mi cuenta
            </router-link>
          </div>
        </v-card-actions>
      </v-form>
    </BrandCustomCard>
  </div>
</template>

<script>
// Components
import BrandCustomCard from '@/components/ui/BrandCustomCard'
import FormButtons from '@/components/ui/FormButtons'
// Mixins
import formMixin from '@/mixins/formMixin'
import uiMixin from '@/mixins/uiMixin'
// Vue-validate plugin
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'

// Services
import { recoverPassword } from '@/services/authentication'

export default {
  name: 'Login',
  components: { BrandCustomCard, FormButtons },
  mixins: [validationMixin, formMixin, uiMixin],

  data() {
    return {
      // Form
      formFields: {
        email: null
      },
      formFieldsValidations: {
        email: {
          required: 'Campo obligatorio',
          email: 'Formato incorrecto de email'
        }
      }
    }
  },
  computed: {
    /**
     * We need a computed option to pass an object with the differents
     * options about the vuetify buttons:
     *
     * https://vuetifyjs.com/en/components/buttons/#buttons
     */
    acceptButtonVOptions() {
      return {
        block: true,
        color: 'primary',
        large: true
      }
    }
  },
  created() {
    this.closeSession()
  },
  methods: {
    /**
     * Is triggering after the form is correctly
     * validated by "Vue-validate"
     */
    async afterSubmit() {
      try {
        await recoverPassword(this.formFields.email)
        // Show info
        this.modifyAppAlert({
          text:
            'Se ha enviado un email con un enlace para cambiar la contraseña',
          visible: true
        })
      } catch (error) {
        // Show error
        this.modifyAppAlert({
          text:
            error.code === 'auth/user-not-found'
              ? 'El email introducido no pertenece a ninguna cuenta'
              : error.message,
          visible: true,
          type: 'error'
        })
      } finally {
        this.formProcessing = false
      }
    }
  },
  // Validations with Vue-validate
  validations: {
    formFields: {
      email: {
        required,
        email
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/theme/variables.scss';
.form-recover-password {
  height: 100%;
  .bottom-links {
    display: flex;
    flex-direction: row;
    justify-content: center;
    a {
      text-decoration: none;
      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }
}
@media (max-width: 768px) {
  .form-recover-password {
    .bottom-links {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      a {
        &:first-child {
          margin-bottom: 0.5rem;
        }
      }
    }
  }
}
</style>
